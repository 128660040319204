import React from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const services = [
  {
    name: "Mobile Developer",
    description:
      "React Native teknolojisiyle, mobil uygulama geliştirme konusunda uzmanlaşmışken, aynı zamanda web geliştirme alanında da React Native'in sunduğu avantajları kullanarak projeler üstleniyorum.",
    link: "Daha Fazlası",
  },
  {
    name: "Web Developer",
    description:
      "Web geliştirme alanında karmaşık projeler üzerinde çalışıyorum. Next.js, React tabanlı bir framework olduğu için, uygulamalarımı geliştirirken bileşen tabanlı ve performans odaklı bir yaklaşım benimsemekteyim.",
    link: "Daha Fazlası",
  },
  {
    name: "Backend Developer",
    description:
      "Node.js teknolojisiyle, backend geliştirme konusunda uzmanlaşıyor, uygulamalarımı güvenilir ve ölçeklenebilir bir şekilde yapılandırmaya özen gösteriyorum.",
    link: "Daha Fazlası",
  },
  {
    name: "UI/UX Design",
    description:
      "UI/UX tasarımında yeni projeler geliştiriyorum ve tasarımlarımı kullanıcı dostu ve etkileyici bir şekilde hayata geçirmeye çalışıyorum.",
    link: "Daha Fazlası",
  },
];

const Services = () => {
  return (
    <section className="section" id="services">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0"
          >
            <h2 className="h2 text-accent mb-6">Neler Yapıyorum ?</h2>
            {/* <h3 className="h3 max-w-[455px] mb-16">Ben bir developerım.</h3> */}
            {/* <button className="btn btn-sm">Çalışmalarımı gör</button> */}
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            <div>
              {services.map((service, index) => {
                const { name, description, link } = service;
                return (
                  <div
                    className="border-b border-white/20 h-[146px] mb-[38px] flex"
                    key={index}
                  >
                    <div className="max-w-[476px]">
                      <h4 className="text-[20] tracking-wider font-primary font-semibold mb-6">
                        {name}
                      </h4>
                      <p className="font-secondary leading-tight xl:text-base lg:text-base md:text-base sm:text-base ss:text-sm">
                        {description}
                      </p>
                    </div>
                    <div className="flex flex-col flex-1 items-end">
                      {/* <a
                        href="#"
                        className="btn w-9 h-9 mb-[42px] flex justify-center items-center"
                      >
                        <BsArrowUpRight />
                      </a> */}
                      {/* <a href="#" className="text-gradient text-sm">
                        {link}
                      </a> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
