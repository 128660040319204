import React from "react";
import Logo from "../assets/logom.png";
import cvPdf from "../assets/Berkan_Buyuk_CV.pdf";

const Header = () => {
  const downloadPdf = () => {
    fetch(cvPdf)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Berkan Büyük CV.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("PDF indirme hatası:", error));
  };

  return (
    <header className="py-8">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <a href="#">
            <img src={Logo} alt="" width="200px" height="200px" />
          </a>
          <button className="btn btn-sm" onClick={downloadPdf}>
            CV İndir
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
