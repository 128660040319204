import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import Img1 from "../assets/portfolio-img1.png";
import Img2 from "../assets/portfolio-img2.png";
import Img3 from "../assets/portfolio-img3.png";

const Work = () => {
  return (
    <section className="section" id="work">
      <div className="container mx-auto xl:mt-5">
        <div className="flex flex-col lg:flex-row gap-x-10">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0"
          >
            <div>
              <h2 className="h2 leading-tight text-accent">
                Github <br /> Projelerim
              </h2>
              <p className="max-w-sm xl:mb-16 lg:mb-8 md:mb-4 sm:mb-2 ss:mb-2">
                Daha fazla öğrenmek ve kendimi geliştirmek için yaptığım
                projeler.
              </p>
              <button className="btn btn-sm">
                <a href="https://github.com/BerkanBuyuk" target="_blank">
                  Tüm projeler
                </a>
              </button>
            </div>

            <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl xl:mt-8">
              <a href="https://github.com/BerkanBuyuk/Aktuelim" target="_blank">
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={Img1}
                  alt=""
                />
                <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">Aktuelim</span>
                </div>
                <div className="absolute -bottom-full left-12 xl:group-hover:bottom-8 lg:group-hover:bottom-2 md:group-hover:bottom-10 sm:group-hover:bottom-8 ss:group-hover:bottom-2 transition-all duration-700 z-50">
                  <span className="text-base text-white">
                    Market ve mağazaların güncel kataloglarını kullanıcıya
                    gösteren, Android ve iOS işletim sisteminde çalışan bir
                    aktuel uygulaması.
                  </span>
                </div>
              </a>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex flex-col gap-y-10"
          >
            <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
              <a href="https://github.com/BerkanBuyuk/Pokedex" target="_blank">
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={Img2}
                  alt=""
                />
                <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">Pokedex</span>
                </div>
                <div className="absolute -bottom-full left-12 xl:group-hover:bottom-8 lg:group-hover:bottom-8 md:group-hover:bottom-14 sm:group-hover:bottom-8 ss:group-hover:bottom-2 transition-all duration-700 z-50">
                  <span className="text-base text-white">
                    Pokemon karakterlerini listeleyen, Android ve iOS işletim
                    sisteminde çalışan bir uygulama.
                  </span>
                </div>
              </a>
            </div>
            <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl ss:mb-20">
              <a href="https://github.com/BerkanBuyuk/MovieApp" target="_blank">
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={Img3}
                  alt=""
                />
                <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">Movie App</span>
                </div>
                <div className="absolute -bottom-full left-12 xl:group-hover:bottom-8 lg:group-hover:bottom-8 md:group-hover:bottom-14 sm:group-hover:bottom-8 ss:group-hover:bottom-2 transition-all duration-700 z-50">
                  <span className="text-base text-white">
                    TMDB API ile film ve oyuncuları listeleyen, Android ve iOS
                    işletim sisteminde çalışan bir uygulama.
                  </span>
                </div>
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Work;
